import QFormBase from "./base/q-form-base";
import "../styles/qform-style.scss";

// Флаг для отслеживания, был ли скрипт GLOBAL ID уже добавлен
let isScriptGlobalAdded = false;
class QFormOrganizer extends QFormBase {
  constructor() {
    let mode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
    super(mode);
  }

  /**
   * инициализация
   * @returns Promise<{then(handler: void)}>
   */
  async init() {
    await this.loadUtilsModule();
    //ищем элементы Qform
    this._findFormsLists();
    this._findForms();
    this._findWidgets();
    const proxyModule = await import( /* webpackChunkName: "proxy" */"./modules/core/proxy");
    this.Proxy = proxyModule.default;
    const transporterModule = await import( /* webpackChunkName: "transporter" */"./modules/core/transporter");
    this.Transporter = transporterModule.transporter;
    const emitterModule = await import( /* webpackChunkName: "Emitter" */"./modules/core/Emitter");
    const Emitter = emitterModule.Emitter;
    this.emitterForms = new Emitter();
    this.emitterStatistics = new Emitter();
    this.emitterFormWidget = new Emitter();
    this.device = this._checkDevice();
    this.formsNodes = null;
    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.mainOptions = {
      server_host: this.server_host,
      upload_host: this.upload_host,
      init_contoller: this.init_contoller,
      init_contollerWidget: this.init_contollerWidget,
      send_controller: this.send_controller,
      upload_controller: this.upload_controller,
      upload_controller_chunks: this.upload_controller_chunks,
      options_controller: this.options_controller,
      style_controller: this.style_controller,
      stat_controller: this.stat_controller,
      stat_widgets_controller: this.stat_widgets_controller,
      next_controller: this.next_controller,
      send_quiz_controller: this.send_quiz_controller,
      agreement_controller: this.agreement_controller,
      localization_controller: this.localization_controller,
      proxy_module: this.Proxy,
      mode: this.mode,
      publicKey: this.publicKey,
      startTimeGlob: this.startTime,
      urlDatePickerJS: this.urlDatePickerJS,
      urlFileFieldJS: this.urlFileFieldJS,
      formWidth: this.formWidth,
      timezone: this.timezone
    };
    this.formsNodes = null;
    this.formsListsNodes = null;
    this.videoWidgetUniqIds = null;
    this.formsListsIds = null;
    return {
      then(handler) {
        return handler();
      }
    };
  }
  async loadUtilsModule() {
    const utilsModule = await import( /* webpackChunkName: "utils" */"./modules/core/utils");
    this.Utils = utilsModule.default;
  }
  _checkDevice() {
    const regexp = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    return regexp.test(navigator.userAgent) ? "mobile" : "desktop";
  }

  /**
   * Если скрипт уже инициализирован
   */
  _setAlreadyInitState() {
    this.QFormFlags.AlreadyInit = true;
  }

  /**
   * Добавляем "метку" на странице что скрипт уже загружен и работает
   */
  _addCommentHead() {
    this.Utils.addCommentHead(' QForm: "I already work here." ');
  }

  /**
   * Если скрипт запущен - продолжаем продолжать в then(()=>{})
   * @returns {{then(*): *}|*}
   * @private
   */
  _alreadyRunning() {
    if (this.QFormFlags.AlreadyInit) return {
      then(handler) {
        return handler();
      }
    };
  }

  /**
   * Инициализируем прокси модуль
   *
   * @private
   */
  _initProxy() {
    this.Proxy.init();
  }
  _findFormsLists() {
    this.formsListsIds = this.Utils.getUniqIds("data-siteid");
  }

  /**
   * Ищем еще не отрендеренные формы на странице
   *
   * @private
   */
  _findForms() {
    window.IdFormsList = this.Utils.getUniqIds("data-formid");
  }

  /**
   * Ищем неотрендеренные виджеты
   * @private
   */
  _findWidgets() {
    this.videoWidgetUniqIds = this.Utils.getUniqIds("data-widget");
  }
  async _renderFormsLists() {
    if (this.formsListsIds.length > 0) {
      if (this.domain.includes(":")) {
        this.domain = this.domain.split(":")[0];
      }
      const params = {
        domain: this.domain,
        action: "list-forms",
        site: JSON.stringify(this.formsListsIds)
      };
      try {
        const response = await fetch(`https://${this.server_host}${this.init_contoller}/list-forms/?${this.Utils.getQueryParamsString(params)}`);
        this.generateFormsLists(await response.json());
      } catch (e) {
        throw new Error("Error during getting forms list", e);
      }
    }
  }
  insertFormListForm(formsIds) {
    const formSiteId = this.Utils.getURLQueryParams().filter(param => param.name === "siteid")[0].value;
    //обычно форма одна, но заложен формат данных в виде массива для возможного дальнейшего изменения логики (показ нескольких форм в элементе списка форм)
    formsIds.forEach(form => {
      const nodes = Array.from(document.querySelectorAll(`div[data-siteid="${formSiteId}"]`));
      if (nodes.length) {
        nodes.forEach(node => {
          node.removeAttribute("data-siteid");
          node.dataset.formid = form.value;
          this.formsNodes.push(node);
        });
      }
    });
  }
  async getFormsOptionsFromAPI(formsIds, customDomain) {
    let addSetting = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    //преобразование доменного имени в имя без порта
    if (this.domain.includes(":")) {
      this.domain = this.domain.split(":")[0];
    }
    const forms = Array.isArray(formsIds) ? JSON.stringify(formsIds) : formsIds;
    const params = {
      domain: customDomain ? customDomain : this.domain,
      action: "init",
      forms,
      timezone: this.timezone
    };
    //настройка, отключающая видеовиджет для первого шага формы
    if (addSetting.noWidgets) {
      params.vw = "no";
    }
    let searchParams = Object.entries(params).map(_ref => {
      let [key, value] = _ref;
      return this.Utils.IsJsonString(value) ? `${encodeURIComponent(key)}=${encodeURIComponent(JSON.parse(value))}` : `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    }).join("&");
    try {
      const data = await fetch(`https://${this.server_host}${this.init_contoller}?${searchParams}`);
      return await data.json();
    } catch (e) {
      console.warn("serverError");
    }
  }
  async _renderForms() {
    if (window.IdFormsList.length) {
      //запрашиваем настройки форм
      const response = await this.getFormsOptionsFromAPI(window.IdFormsList);
      for (let key in response.forms) {
        if (response.forms[key].videowidgetId && response.forms[key].displayType === 0) {
          const widgetNode = document.createElement("div");
          widgetNode.setAttribute("data-widget", response.forms[key].videowidgetId);
          document.body.append(widgetNode);
        }
      }
      this._findWidgets();
      this.generateFreeForms(response);
    }
  }
  async renderFormById(formId) {
    //запрашиваем настройки для формы
    const response = await this.getFormsOptionsFromAPI([formId]);
    this.generateFreeForms(response);
  }
  _renderWidgets() {
    this._findWidgets();
    if (this.videoWidgetUniqIds.length) {
      import("./modules/form/videoWidgetsGenerator.js").then(async module => {
        const widgetsGenerator = new module.videoWidgetsGenerator(this.mainOptions, this.domain, this.device, this.startTime, this.emitterFormWidget);
        await widgetsGenerator.init();
        widgetsGenerator._renderWidgets(this.videoWidgetUniqIds);
      });
    }
  }
  _cleanSubscriptions(listenners) {
    if (Object.keys(listenners).length) {
      Object.keys(listenners).map(formId => {
        listenners[formId].splice(0, listenners[formId].length);
      });
    }
  }
  async _rebuildForms() {
    // Чистим список подписок, если на фронте уже есть данные формы, и они перестраиваются
    this._cleanSubscriptions(this.emitterStatistics.listeners);
    this._cleanSubscriptions(this.emitterForms.listeners);
    this.clearFreewidgetsWrappers();
    this.formsNodes = [...this.Utils.getAllElements(`[data-formid]`)];
    this.formsListsNodes = [...this.Utils.getAllElements(`[data-siteid]`, "div")];
    await this.addLoaderElement([...this.formsNodes, ...this.formsListsNodes]);
    const urlFormsIds = this.checkURLFormIds();
    if (urlFormsIds.length > 0) {
      this.insertFormListForm(urlFormsIds);
    }
    this._findFormsLists();
    this._findForms();
    this._findWidgets();
    await this._renderFormsLists();
    await this._renderForms();
    this._renderWidgets();
  }

  /**
   * Дефолтная точка входа
   *
   * @returns {Promise<void>}
   */
  async launchAuto() {
    await this.init().then(() => {
      let regexp = /<!-- QForm: "I already work here." -->/gs;
      let result = this.Utils.getAllElements("head")[0].innerHTML.match(regexp);
      if (result) {
        this._setAlreadyInitState();
      } else {
        this._addCommentHead();
        this._setAlreadyInitState();
      }

      // </ Проверка повторного запуска скрипта>
      this._alreadyRunning().then(async () => {
        this.clearFreewidgetsWrappers();
        this.formsNodes = [...this.Utils.getAllElements(`[data-formid]`, "div")];
        this.formsListsNodes = [...this.Utils.getAllElements(`[data-siteid]`, "div")];
        await this.addLoaderElement([...this.formsNodes, ...this.formsListsNodes]);
        this._initProxy();
        const urlFormsIds = this.checkURLFormIds();
        if (urlFormsIds.length > 0) {
          this.insertFormListForm(urlFormsIds);
        }
        this._findFormsLists();
        this._findForms();
        this._findWidgets();
        await this._renderFormsLists();
        await this._renderForms();
        this._renderWidgets();
      });
    });
  }
  checkURLFormIds() {
    return this.Utils.getURLQueryParams().filter(param => param.name === "list-formid");
  }
  async runStatistic() {
    let {
      type = "forms",
      objectsToRender = {}
    } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    let qformIds = Object.keys(objectsToRender) || [];
    let data = {
      url: this.server_host + this.stat_controller,
      startTime: this.startTime,
      emitter: this.emitterStatistics,
      qformIds
    };
    const module = await import( /* webpackChunkName: "StatisticsCollector" */"./modules/core/statisticsCollector");
    const stat = new module.StatisticsCollector(data);
    await stat.init();
  }

  /*
   *
   * */
  renderFormByType(formType, node, settings) {
    switch (formType) {
      case 0:
        import( /* webpackChunkName: "FormModule" */"./modules/form/form").then(module => {
          const qform = new module.Form(node, settings);
          return qform.render();
        }
        // <--lang_school
        ).then(() => {
          //   //выполнение JS после отстройки форм
          eval(window.qformOddJS);
        });
        //-->
        break;
      case 1:
        import( /* webpackChunkName: "QuizModule" */"./modules/form/quiz").then(module => {
          const qform = new module.Quiz(node, settings);
          qform.render();
        });
        // useQtabnav = true;
        break;
      default:
        import( /* webpackChunkName: "FormModule" */"./modules/form/form").then(module => {
          const qform = new module.Form(node, settings);
          qform.render();
        });
        break;
    }
  }
  addCaptcha(recaptchaVersion, keyCaptcha) {
    this.Utils.addJsFile(`https://www.google.com/recaptcha/api.js?render=${recaptchaVersion === 3 ? keyCaptcha : "explicit"}`);
    this.QFormFlags.AlreadyCaptchaJS = true;
  }
  generateFormsLists(data) {
    for (let site in data) {
      //находим элементы для отсройки списка
      const nodes = this.formsListsNodes.filter(node => node.dataset.siteid === site);
      nodes.forEach(node => {
        const $list = document.createElement("ul");
        $list.classList.add("qform__forms-list");
        for (let form in data[site].forms) {
          const formData = data[site].forms[form];
          $list.insertAdjacentHTML("beforeend", `
            <li class="qform__forms-list-item">
              <a 
                class="qform__forms-list-item__link" 
                href="${window.location.origin}${window.location.pathname}?list-formid=${formData.formID}&siteid=${formData.siteId}">${formData.name}
              </a>
            </li>
          `);
        }
        node.innerHTML = "";
        node.insertAdjacentElement("afterbegin", $list);
      });
    }
  }
  async generateFreeForms(data) {
    let forms = data.forms || [];
    let useRecaptcha = 0;
    let keyCaptcha = "";
    let recaptchaVersion = 2;
    await this.runStatistic({
      type: "forms",
      objectsToRender: forms
    }); // запускаем сбор статистики

    window.IdFormsList.forEach(id => {
      //находим все корневые элементы формы
      const currentFormNodes = this.formsNodes.filter(node => node.dataset.formid === id);
      if (forms[id]) {
        if (forms[id].error) {
          //добавляем сообщение об ошибке отстройки формы
          currentFormNodes.forEach(node => {
            node.innerHTML = forms[id].error || "Form is not available";
          });
          return;
        }
        if (!forms[id].licenseIsActive) {
          currentFormNodes.forEach(node => {
            const noLicenseMessage = data.forms[id].messages.licenseExpired || "Form is unavalilable. Please, check settings";
            node.innerHTML = this.getNoLicenseMessage(noLicenseMessage);
          });
          return;
        }
        if (forms[id].active) {
          const nodes = [];
          currentFormNodes.forEach(node => {
            nodes.push(node);
          });
          if (!nodes.length) {
            console.warn(`QFormOrganizer->generateFreeForms: "Не найден родительский элемент формы ${id}!"`);
          }

          // console.log(forms)
          // console.log(forms[id])
          nodes.forEach((node, index) => {
            node.classList.add("is_qform");
            node.setAttribute("data-q-form-index", index);
            let settings = {
              options: {
                // настройки скрипта
                ...this.mainOptions,
                // domain: this.mode ? this.domain : node.dataset.domain,
                domain: this.domain,
                // настройки формы
                formIndex: index
              },
              data: forms[id],
              emiterGlob: this.emitterForms,
              emitterStatistics: this.emitterStatistics,
              emitterFormWidget: this.emitterFormWidget,
              device: this.device
            };
            forms[id].useRecaptcha === 1 ? useRecaptcha = 1 : null;
            keyCaptcha = forms[id].captchaSitekey;
            recaptchaVersion = forms[id].recaptchaVersion || 2;
            const formID = forms[id].formID;
            if (!forms[id].saveInStorage) {
              localStorage.removeItem(formID);
            } else if (localStorage.getItem(formID) === null) {
              localStorage.setItem(formID, JSON.stringify({}));
            }
            this.renderFormByType(forms[id].formType, node, settings);
          });

          // Добавления скрипта авторизации в GlobalId
          /*console.log("init global");
          if (!isScriptGlobalAdded) {
            const qFormGlobalScript = document.createElement("script");
            qFormGlobalScript.type = "module";
            qFormGlobalScript.src =
              process.env.QFORM_GLOBAL_CDN + "?t=" + new Date().getTime();
            document.head.appendChild(qFormGlobalScript);
            const comment = document.createComment(" QForm Global ID ");
            qFormGlobalScript.before(comment);
            isScriptGlobalAdded = true;
          }*/
        } else {
          this.currentFormNodes.forEach(node => {
            node.innerHTML = "";
          });
        }
      } else {
        // находим элемент и очищаем его
        const emptyFromNode = currentFormNodes.find(node => node.dataset.formid === id);
        emptyFromNode.innerHTML = "";
      }
    });
    // Добавляем Captcha
    if (useRecaptcha !== 0 && !this.QFormFlags.AlreadyCaptchaJS) {
      this.addCaptcha(recaptchaVersion, keyCaptcha);
    }
    generateCustomStyles(data.forms);
    addGooglePreloadLinks(data.forms);
    addGoogleFontsLinks(data.forms);
  }
  async generateOneForm(node, formid) {
    let addSetting = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    node.classList.add("is_qform");
    let link = this.Utils.parseURL(this.mode ? "https://" + this.domain : "https://" + node.dataset.domain);
    let domain = node.dataset.domain || link.hostname;
    // let domain = 'dev-app.qform.io';

    const data = await this.getFormsOptionsFromAPI(formid, domain, addSetting);
    let forms = data.forms || {};
    this.runStatistic(forms); // запускаем сбор статистики
    let useRecaptcha = 0;
    let keyCaptcha = "";
    let recaptchaVersion = 2;
    if (forms[formid]) {
      if (!forms[formid].licenseIsActive) {
        const noLicenseMessage = data.forms[formid].messages.licenseExpired || "Form is unavalilable. Please, check settings";
        node.innerHTML = this.getNoLicenseMessage(noLicenseMessage);
        return;
      }
      if (forms[formid].active) {
        let settings = {
          options: {
            // настройки скрипта
            ...this.mainOptions,
            domain: this.mode ? this.domain : node.dataset.domain,
            // настройки формы
            formIndex: "one-form_" + new Date().getTime()
          },
          data: forms[formid],
          emiterGlob: this.emitterForms,
          emitterStatistics: this.emitterStatistics,
          emitterFormWidget: this.emitterFormWidget,
          extraSettings: addSetting,
          device: this.device
        };
        forms[formid].useRecaptcha === 1 ? useRecaptcha = 1 : null;
        keyCaptcha = forms[formid].captchaSitekey;
        recaptchaVersion = forms[formid].recaptchaVersion || 2;
        if (addSetting.noWidgets) {
          settings.inWidget = true;
        }
        this.renderFormByType(forms[formid].formType, node, settings);
      } else {
        node.innerHTML = "";
      }
    }

    // Добавляем Captcha
    if (useRecaptcha !== 0 && !this.QFormFlags.AlreadyCaptchaJS) {
      this.addCaptcha(recaptchaVersion);
    }
    generateCustomStyles(data.forms);
    addGooglePreloadLinks(data.forms);
    addGoogleFontsLinks(data.forms);
  }
  getNoLicenseMessage(message) {
    return `<p style="text-align: center; width: 100%;">${message}</p>`;
  }
  clearFreewidgetsWrappers() {
    const wrappers = document.getElementsByClassName("qform__freewidgets-wrapper");
    if (wrappers.length) {
      for (let i = 0; i < wrappers.length; i++) {
        // wrappers[i].parentElement.insertAdjacentHTML('beforeend', wrappers[i].innerHTML)
        // wrappers[i].remove()
        wrappers[i].innerHTML = "";
      }
    }
  }
  captchaOnLoad() {
    this.emitterForms.emit("Organizer:onloadCaptcha", {});
  }
  async getLoaderClass() {
    return await import( /* webpackChunkName: "LoaderComponent" */"./modules/components/loader/Loader.component.js").then(data => {
      return data.LoaderComponent;
    });
  }
  async addLoaderElement() {
    let nodes = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    const LoaderConstructor = await this.getLoaderClass().then(instance => {
      return instance;
    });
    nodes = [...nodes];
    nodes.forEach(node => {
      //если не установлен прелодаер в HTML
      if (node.dataset?.preloader !== "2") {
        try {
          const Loader = new LoaderConstructor({
            formId: node.dataset.formid || `list_${node.dataset.siteid}`
          });
          Loader.init();
          //предварительно очищаем корневой элемент формы
          node.innerHTML = "";
          node.insertAdjacentHTML("beforeend", Loader.toHTML());
        } catch (error) {
          return new Error(`QFormOrganizer => addLoaderElement: "Родитель не является DOM элементом"`);
        }
      }
    });
  }
}
function addGooglePreloadLinks(forms) {
  //если хотя бы одна форма использует дизайн куформ и шрифт на равен null ('inherit'), то устанавливаем солединение с google fonts
  const formUsesDesign = Object.keys(forms).find(key => forms[key].useDesign && forms[key].style.formStyleText.font);
  if (formUsesDesign) {
    let preconnectFirstLink = document.head.querySelector(`link#google_preconnect_first`);
    let preconnectSecondLink = document.head.querySelector(`link#google_preconnect_second`);
    if (!preconnectFirstLink) {
      document.head.insertAdjacentHTML("beforeend", `<link id="google_preconnect_first" rel="preconnect" href="https://fonts.googleapis.com">`);
    }
    if (!preconnectSecondLink) {
      document.head.insertAdjacentHTML("beforeend", `<link id="google_preconnect_second" rel="preconnect" href="https://fonts.gstatic.com" crossorigin>`);
    }
  }
}
/**
 * Конструктор класса для создания объекта.
 * @param {Object<string,DataForm>} forms
 */
function addGoogleFontsLinks(forms) {
  for (let elem in forms) {
    const form = forms[elem];
    if (form.useDesign) {
      const fontData = form.style.formStyleText.font;
      const fontId = fontData?.name?.replace(/\s/, "");
      const fontLink = document.head.querySelector(`link#${fontId}`);
      if (!fontLink && fontId) {
        document.head.insertAdjacentHTML("beforeend", `<link id="${fontId}" href="${fontData.googleLink}" rel="stylesheet">`);
      }
    }
  }
}
function generateCustomStyles(forms) {
  let index = 0;
  for (let key in forms) {
    const useDesign = forms[key].useDesign;
    if (useDesign) {
      const formStyles = forms[key].style;
      const indexOfElem = Object.keys(forms).indexOf(key);
      const $style = document.createElement("style");
      $style.id = key + "_base";
      $style.innerHTML += `
      /* основной размер текста */
        div[data-formid="${key}"] form.qform,
        div[data-formid="${key}"] div.btn_modal-box,
        div[data-formid="${key}"] div.qform-tabnav-here,
        .form-overlay[id^=modal_${key}] form.qform,
        div[id="agreement_${key}_${indexOfElem}"].qform-agreement__overlay, 
        div[data-formid="${key}"] .message_from_server p {
          font-size: ${formStyles.formStyleText.fontSize}px !important;
          ${formStyles.formStyleText.font?.cssName.slice(0, -1) ?? "font-family: inherit"} !important;
        }
      `;
      const fontSizeRatio = formStyles.formStyleText.fontSize / 14;
      const timepickerFontSizeRatio = fontSizeRatio * 0.75;
      if (document.documentElement.clientWidth > 576) {
        //стили для datepicker поля Дата
        const widthToSet = 313 * fontSizeRatio + "px";
        //14px - изначальный шрифт для форм

        $style.innerHTML += `
        /* ширина datepicker */
        div[data-formid="${key}"] form.qform .qform-row .qform-field.datepickers .qform-field__block_box__field .qform-field__block_box__field__view {
          width: ${widthToSet};
        }
  
        /* размеры кнопок для выбора дня месяца */
        div[data-formid="${key}"] form.qform .qform-row .qform-field.datepickers .datepicker__cell-day {
          /* 40px - изначальные размеры длины и ширины */
          width: ${40 * fontSizeRatio}px;
          height: ${40 * fontSizeRatio}px;
        }
  
        /* размеры кнопок для выбора месяца и года */
        div[data-formid="${key}"] form.qform .qform-row .qform-field.datepickers .datepicker__cell-month,
        div[data-formid="${key}"] form.qform .qform-row .qform-field.datepickers .datepicker__cell-year {
          /* 68px и 40px - изначальные размеры длины и ширины */
          width: ${68 * fontSizeRatio}px;
          height: ${40 * fontSizeRatio}px;
        }
  
        /* padding кнопки "Сегодня", влияющий на ее размер */
        div[data-formid="${key}"] form.qform .qform-row .qform-field.datepickers .qform-field__block_box__field .qform-field__block_box__field__view_nav-top .btn-now {
          /* 4px и 12px - изначальные размеры паддингов */
          padding: ${4 * fontSizeRatio}px ${12 * fontSizeRatio}px;
        }
        `;
      }
      if (formStyles.formStyleText.useCustomColors) {
        $style.innerHTML += `
        /* основной цвет текста */
        div[data-formid="${key}"],
        div[data-formid="${key}"] form.qform .qform-field__block_box__field-box_input,
        div[data-formid="${key}"] form.qform .qform-row .qform-field.select input.qform-field__block_box__field-box_input-text,
        div[data-formid="${key}"] form.qform .qform-row .qform-field.select .qform-field__block_box__field-box_list__item,
        div[data-formid="${key}"] form.qform .qform-row .qform-field.checkbox .qform-field__block_box__field-box__label,
        div[data-formid="${key}"] form.qform .qform-row .qform-field.radio .qform-field__block_box__field-box__label,
        div[data-formid="${key}"] div.qform-tabnav-here .qform_tab-nav .qform_tab-nav__item .qform_tab-nav__title>span,
        div[data-formid="${key}"] div.qform-tabnav-here .qform_line-nav .qform_line-nav__title,
        div[data-formid="${key}"] div.qform-tabnav-here .qform_line-nav .qform_line-nav__step-all,
        div[data-formid="${key}"] form.qform .qform-row .qform-field__textblock_text,
        div[data-formid="${key}"] form.qform .qform-field__block_box__field-box_input,
        div[data-formid="${key}"] form.qform .qform-other-item__input,
        div[data-formid="${key}"] form.qform .qform-field__submit_agree-box__text,
        div[data-formid="${key}"] form.qform.qform-theme-black .qform-row .qform-field__textblock_text,
        div[data-formid="${key}"] form.qform.qform-theme-black .qform-field__block_box__field-box_input,
        div[data-formid="${key}"] form.qform.qform-theme-black .qform-field__submit_agree-box__text,
        div[data-formid="${key}"] form.qform-theme-black .qform-row .qform-field.select .qform-field__block_box__field-box_list__item,
        div[id="agreement_${key}_${indexOfElem}"].qform-agreement__overlay.qform-agreement-open,
        div[id*="agreement_${key}_one-form"].qform-agreement__overlay.qform-agreement-open
        {
          color: ${formStyles.formStyleText.defaultColor};
        }
        div[data-formid="${key}"] div.qform-tabnav-here .qform_line-nav .qform_line-nav__step-all>span {
          color: ${formStyles.formStyleText.defaultColor};
          opacity: .5
        }
        /* цвет сообщения об ошибке */
        div[data-formid="${key}"] form.qform div.qform-row div.qform-field__bottom-box_message__error,
        div[data-formid="${key}"] form.qform .qform-field__submit_agree-box__error-box_message {
          color: ${formStyles.formStyleText.errorColor};
        }
        /* цвет заголовка */
        div[data-formid="${key}"] form.qform .qform-row label.qform-field-outside-label-box_label, 
        div[data-formid="${key}"] form.qform .qform-row label.qform-field__block_box__field-box_label,
        div[data-formid="${key}"] form.qform .qform-row .qform-field__textblock_label,
        div[data-formid="${key}"] form.qform.qform-theme-black .qform-row .qform-field label.qform-field-outside-label-box_label, 
        div[data-formid="${key}"] form.qform.qform-theme-black .qform-row .qform-field label.qform-field__block_box__field-box_label,
        div[data-formid="${key}"] form.qform.qform-theme-black .qform-row .qform-field .qform-field__textblock_label {
          color: ${formStyles.formStyleText.fieldTitleColor};
        }
        /* цвет текста подсказки */
        div[data-formid="${key}"] form.qform .qform-row .qform-field-near-label-box .qform-field_hint_message, 
        div[data-formid="${key}"] form.qform .qform-row .qform-field-outside-label-box .qform-field_hint_message, 
        div[data-formid="${key}"] form.qform .qform-row .qform-field_att-in_left .qform-field_hint_message, 
        div[data-formid="${key}"] form.qform .qform-row .qform-field_att-in_right .qform-field_hint_message, 
        div[data-formid="${key}"] form.qform .qform-row .qform-field_att-out_left .qform-field_hint_message, 
        div[data-formid="${key}"] form.qform .qform-row .qform-field_att-out_right .qform-field_hint_message,
        div[data-formid="${key}"] form.qform.qform-hint_bottom .qform-row .qform-field.qform-hint .qform-field__bottom-box_message__hint {
          color: ${formStyles.formStyleText.hintColor};
        }
        /* цвет плейсхолдера */
        div[data-formid="${key}"] form.qform:not(.qform-label_inside_show):not(.qform-label_inside_hide):not(.qform-label_outside_top.qform-placeholder_hide):not(.qform-label_outside_left.qform-placeholder_hide) .qform-row .qform-field__block_box__field-box_input::placeholder,
        div[data-formid="${key}"] form.qform .qform-row .qform-field.select input.qform-field__block_box__field-box_input-text.qform-default-value,
        div[data-formid="${key}"] form.qform input.qform-other-item__input::placeholder
        {
          color: ${formStyles.formStyleText.placeholderColor};
        }
        `;
        $style.innerHTML += `
        /* основной цвет текста */
        .form-overlay[id^=modal_${key}],
        .form-overlay[id^=modal_${key}] form.qform .qform-field__block_box__field-box_input,
        .form-overlay[id^=modal_${key}] form.qform .qform-row .qform-field.select input.qform-field__block_box__field-box_input-text,
        .form-overlay[id^=modal_${key}] form.qform .qform-row .qform-field.select .qform-field__block_box__field-box_list__item,
        .form-overlay[id^=modal_${key}] form.qform .qform-row .qform-field.checkbox .qform-field__block_box__field-box__label,
        .form-overlay[id^=modal_${key}] form.qform .qform-row .qform-field.radio .qform-field__block_box__field-box__label,
        .form-overlay[id^=modal_${key}] div.qform-tabnav-here .qform_tab-nav .qform_tab-nav__item .qform_tab-nav__title>span,
        .form-overlay[id^=modal_${key}] div.qform-tabnav-here .qform_line-nav .qform_line-nav__title,
        .form-overlay[id^=modal_${key}] div.qform-tabnav-here .qform_line-nav .qform_line-nav__step-all,
        .form-overlay[id^=modal_${key}] form.qform .qform-row .qform-field__textblock_text,
        .form-overlay[id^=modal_${key}] form.qform .qform-field__block_box__field-box_input,
        .form-overlay[id^=modal_${key}] form.qform .qform-other-item__input,
        .form-overlay[id^=modal_${key}] form.qform .qform-field__submit_agree-box__text,
        .form-overlay[id^=modal_${key}] form.qform.qform-theme-black .qform-row .qform-field__textblock_text,
        .form-overlay[id^=modal_${key}] form.qform.qform-theme-black .qform-field__block_box__field-box_input,
        .form-overlay[id^=modal_${key}] form.qform.qform-theme-black .qform-field__submit_agree-box__text,
        .form-overlay[id^=modal_${key}] form.qform-theme-black .qform-row .qform-field.select .qform-field__block_box__field-box_list__item,
        div[id="agreement_${key}_${indexOfElem}"].qform-agreement__overlay.qform-agreement-open,
        div[id*="agreement_${key}_one-form"].qform-agreement__overlay.qform-agreement-open
        {
          color: ${formStyles.formStyleText.defaultColor};
        }
        .form-overlay[id^=modal_${key}] div.qform-tabnav-here .qform_line-nav .qform_line-nav__step-all>span {
          color: ${formStyles.formStyleText.defaultColor};
          opacity: .5
        }
        /* цвет сообщения об ошибке */
        .form-overlay[id^=modal_${key}] form.qform div.qform-row div.qform-field__bottom-box_message__error,
        .form-overlay[id^=modal_${key}] form.qform .qform-field__submit_agree-box__error-box_message {
          color: ${formStyles.formStyleText.errorColor};
        }
        /* цвет заголовка */
        .form-overlay[id^=modal_${key}] form.qform .qform-row label.qform-field-outside-label-box_label, 
        .form-overlay[id^=modal_${key}] form.qform .qform-row label.qform-field__block_box__field-box_label,
        .form-overlay[id^=modal_${key}] form.qform .qform-row .qform-field__textblock_label,
        .form-overlay[id^=modal_${key}] form.qform.qform-theme-black .qform-row .qform-field label.qform-field-outside-label-box_label, 
        .form-overlay[id^=modal_${key}] form.qform.qform-theme-black .qform-row .qform-field label.qform-field__block_box__field-box_label,
        .form-overlay[id^=modal_${key}] form.qform.qform-theme-black .qform-row .qform-field .qform-field__textblock_label {
          color: ${formStyles.formStyleText.fieldTitleColor};
        }
        /* цвет текста подсказки */
        .form-overlay[id^=modal_${key}] form.qform .qform-row .qform-field-near-label-box .qform-field_hint_message, 
        .form-overlay[id^=modal_${key}] form.qform .qform-row .qform-field-outside-label-box .qform-field_hint_message, 
        .form-overlay[id^=modal_${key}] form.qform .qform-row .qform-field_att-in_left .qform-field_hint_message, 
        .form-overlay[id^=modal_${key}] form.qform .qform-row .qform-field_att-in_right .qform-field_hint_message, 
        .form-overlay[id^=modal_${key}] form.qform .qform-row .qform-field_att-out_left .qform-field_hint_message, 
        .form-overlay[id^=modal_${key}] form.qform .qform-row .qform-field_att-out_right .qform-field_hint_message,
        .form-overlay[id^=modal_${key}] form.qform.qform-hint_bottom .qform-row .qform-field.qform-hint .qform-field__bottom-box_message__hint {
          color: ${formStyles.formStyleText.hintColor};
        }
        /* цвет плейсхолдера */
        .form-overlay[id^=modal_${key}] form.qform:not(.qform-label_inside_show):not(.qform-label_inside_hide):not(.qform-label_outside_top.qform-placeholder_hide):not(.qform-label_outside_left.qform-placeholder_hide) .qform-row .qform-field__block_box__field-box_input::placeholder,
        .form-overlay[id^=modal_${key}] form.qform .qform-row .qform-field.select input.qform-field__block_box__field-box_input-text.qform-default-value,
        .form-overlay[id^=modal_${key}] form.qform input.qform-other-item__input::placeholder
        {
          color: ${formStyles.formStyleText.placeholderColor};
        }
        `;
      }
      $style.innerHTML += `
        /* стили для timepicker */
        div[data-formid="${key}"] form.qform .qform-row .qform-field.time .qform-field__block_box__field .qform-field__block_box__field__view {
          /* 168px - изначальная ширина timepicker, 0.75 - коэффициент для более пропорционального увеличения ширины */
          width: ${168 * timepickerFontSizeRatio}px; 
        }

        div[data-formid="${key}"] form.qform .qform-row .qform-field.time .times {
          @media (min-width: 577px) {
            max-height: ${288 * timepickerFontSizeRatio}px;
          }
        }

        div[data-formid="${key}"] form.qform .qform-row .qform-field.time .times__minutes,
        div[data-formid="${key}"] form.qform .qform-row .qform-field.time .times__hours {
          /* 30px - изначальное значение margin-top */
          margin-top: ${30 * timepickerFontSizeRatio}px; 
        }

        div[data-formid="${key}"] form.qform .qform-row .qform-field.time .times__minutes-title,
        div[data-formid="${key}"] form.qform .qform-row .qform-field.time .times__hours-title {
          /* -30px - изначальное значение top */
          top: -${30 * timepickerFontSizeRatio}px; 
        }
      `;
      document.head.append($style);
      if (formStyles.formStyleSubmitButton.useDesign) {
        const formStyleSubmitButton = document.createElement("style");
        formStyleSubmitButton.id = key + "_formStyleSubmitButton";
        formStyleSubmitButton.innerHTML += `
        /* размер шрифта кнопок "отправить", "далее" и "назад" */
        div[data-formid="${key}"] form.qform .qform-field__submit .submit-box {
          font-size: ${formStyles.formStyleSubmitButton.fontSize}px;
        }
        div[data-formid="${key}"] form.qform .qform-field__submit .submit-box input.qform-field__submit__input.submit-button,
        div[data-formid="${key}"] form.qform .qform-field .qform-field__quiz-btn .qform-field__quiz-btn-box input
        {
          font-size: ${formStyles.formStyleSubmitButton.fontSize}px;
        }
        /* цвет фона и шрифта кнопки "отправить" и "далее" */
        div[data-formid="${key}"] form.qform .qform-field__submit:not(.qform-submit-disabled) .submit-box input,
        .form-overlay[id^=modal_${key}] form .qform-field__submit__input,
        div[data-formid="${key}"] form.qform .qform-row .qform-field .qform-field__quiz-btn .qform-field__quiz-btn-box:not(.qform-submit-disabled) input.qform-field__quiz-btn__btn-next,
        div[data-formid="${key}"] form.qform .qform-row .qform-field .qform-field__quiz-btn .qform-field__quiz-btn-box:not(.qform-submit-disabled) input.qform-field__quiz-btn__btn-send {
          color: ${formStyles.formStyleSubmitButton.color};
          background-color: ${formStyles.formStyleSubmitButton.backgroundColor};
        }
        /* цвет элементов стрелочки при соответствуюшем стиле наведения */
        div[data-formid="${key}"] form.qform .qform-field__submit.qform-hover-arrow:not(.qform-submit-disabled) .submit-box::before,
        div[data-formid="${key}"] .qform-field__quiz-btn-box.qform__hover-arrow:not(.qform-submit-disabled)::before {
          background-color: ${formStyles.formStyleSubmitButton.color};
        }
        div[data-formid="${key}"] form.qform .qform-field__submit.qform-hover-arrow:not(.qform-submit-disabled) .submit-box::after,
        div[data-formid="${key}"] .qform-field__quiz-btn .qform-field__quiz-btn-box.qform__hover-arrow:not(.qform-submit-disabled)::after {
          border-color: ${formStyles.formStyleSubmitButton.color};
        }
        div[data-formid="${key}"] form.qform .qform-row .qform-field  a {
          color: ${formStyles.formStyleSubmitButton.backgroundColor};
        }
        `;
        // Для модалки
        formStyleSubmitButton.innerHTML += `
        /* размер шрифта кнопок "отправить", "далее" и "назад" */
        .form-overlay[id^=modal_${key}] form.qform .qform-field__submit .submit-box {
          font-size: ${formStyles.formStyleSubmitButton.fontSize}px;
        }
        .form-overlay[id^=modal_${key}] form.qform .qform-field__submit .submit-box input.qform-field__submit__input.submit-button,
       .form-overlay[id^=modal_${key}] form.qform .qform-field .qform-field__quiz-btn .qform-field__quiz-btn-box input
        {
          font-size: ${formStyles.formStyleSubmitButton.fontSize}px;
        }
        /* цвет фона и шрифта кнопки "отправить" и "далее" */
        .form-overlay[id^=modal_${key}] form.qform .qform-field__submit:not(.qform-submit-disabled) .submit-box input,
        .form-overlay[id^=modal_${key}] form .qform-field__submit__input,
        .form-overlay[id^=modal_${key}] form.qform .qform-row .qform-field .qform-field__quiz-btn .qform-field__quiz-btn-box:not(.qform-submit-disabled) input.qform-field__quiz-btn__btn-next,
        .form-overlay[id^=modal_${key}] form.qform .qform-row .qform-field .qform-field__quiz-btn .qform-field__quiz-btn-box:not(.qform-submit-disabled) input.qform-field__quiz-btn__btn-send {
          color: ${formStyles.formStyleSubmitButton.color};
          background-color: ${formStyles.formStyleSubmitButton.backgroundColor};
        }
        /* цвет элементов стрелочки при соответствуюшем стиле наведения */
        .form-overlay[id^=modal_${key}] form.qform .qform-field__submit.qform-hover-arrow:not(.qform-submit-disabled) .submit-box::before,
        .form-overlay[id^=modal_${key}] .qform-field__quiz-btn-box.qform__hover-arrow:not(.qform-submit-disabled)::before {
          background-color: ${formStyles.formStyleSubmitButton.color};
        }
        .form-overlay[id^=modal_${key}] form.qform .qform-field__submit.qform-hover-arrow:not(.qform-submit-disabled) .submit-box::after,
        .form-overlay[id^=modal_${key}] .qform-field__quiz-btn .qform-field__quiz-btn-box.qform__hover-arrow:not(.qform-submit-disabled)::after {
          border-color: ${formStyles.formStyleSubmitButton.color};
        }
        .form-overlay[id^=modal_${key}] form.qform .qform-row .qform-field  a {
          color: ${formStyles.formStyleSubmitButton.backgroundColor};
        }
        `;
        document.head.append(formStyleSubmitButton);
      }
      if (forms[key].buttonUseDesignStyles) {
        const buttonUseDesignStyles = document.createElement("style");
        buttonUseDesignStyles.id = key + "_buttonUseDesignStyles";
        buttonUseDesignStyles.innerHTML += `
        /* стили для кнопки формы в модальном окне */
        .form-overlay[id^=modal_${key}] div.btn_modal-box {
          font-size: ${forms[key].buttonFontSize}px;
        }
        div[data-formid="${key}"] div div.btn_modal-box input.btn-modal {
          padding: 16px 32px;
          background-color: ${forms[key].buttonBackgroundColor};
          color: ${forms[key].buttonTextColor};
          font-size: ${forms[key].buttonFontSize}px;
        }
        /* цвет элементов стрелочки при соответствуюшем стиле наведения */
        div[data-formid="${key}"] div div.btn_modal-box.qform-hover-arrow::before {
          background-color: ${forms[key].buttonTextColor};
        }
        div[data-formid="${key}"] div div.btn_modal-box.qform-hover-arrow::after {
          border-color: ${forms[key].buttonTextColor};
        }
        `;
        document.head.append(buttonUseDesignStyles);
      }
      if (formStyles.formStyleFieldState.useDesign) {
        const formStyleFieldState = document.createElement("style");
        formStyleFieldState.id = key + "_formStyleFieldState";
        if (formStyles.formStyleFieldState.changeColorOnFocus && formStyles.formStyleFieldState.colorOnFocus) {
          formStyleFieldState.innerHTML += `
          /* стили полей при наведении и фокусе */
          div[data-formid="${key}"] form.qform .qform-row .qform-field .qform-field_wrap .qform-field__block .qform-field__block_box__field.qform-field-focus,
          div[data-formid="${key}"] form.qform .qform-other-item__box.qform-field-focus:not(.qform-field-error)
          {
            border-color: ${formStyles.formStyleFieldState.colorOnFocus};
          }
          `;
          formStyleFieldState.innerHTML += `
          /* стили полей при наведении и фокусе */
         .form-overlay[id^=modal_${key}] form.qform .qform-row .qform-field .qform-field_wrap .qform-field__block .qform-field__block_box__field.qform-field-focus,
         .form-overlay[id^=modal_${key}] form.qform .qform-other-item__box.qform-field-focus:not(.qform-field-error)
          {
            border-color: ${formStyles.formStyleFieldState.colorOnFocus};
          }
          `;
        }
        if (formStyles.formStyleFieldState.changeColorOnHover && formStyles.formStyleFieldState.colorOnHover) {
          formStyleFieldState.innerHTML += `
          /* стили полей при наведении и фокусе */
          div[data-formid="${key}"] form.qform .qform-row .qform-field .qform-field_wrap .qform-field__block .qform-field__block_box__field:hover,
          div[data-formid="${key}"] form.qform .qform-other-item__box:hover
          {
            border-color: ${formStyles.formStyleFieldState.colorOnHover};
          }
          `;
          formStyleFieldState.innerHTML += `
          /* стили полей при наведении и фокусе */
          .form-overlay[id^=modal_${key}] form.qform .qform-row .qform-field .qform-field_wrap .qform-field__block .qform-field__block_box__field:hover,
          .form-overlay[id^=modal_${key}] form.qform .qform-other-item__box:hover
          {
            border-color: ${formStyles.formStyleFieldState.colorOnHover};
          }
          `;
        }
        document.head.append(formStyleFieldState);
      }
    }
    index++;
  }
}
function launchAuto() {
  if (!window.QFormOrganizer) {
    const organizer = new QFormOrganizer();
    window.QFormOrganizer = organizer;
    organizer.launchAuto();
  }
}
function launchForm(selector) {
  let formId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  let addSetting = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  let node = document.querySelectorAll(selector);
  if (node.length > 0) {
    if (!formId) {
      formId = node.dataset.formid || null;
    } else {
      const organizer = new QFormOrganizer();
      node.forEach(el => {
        organizer.generateOneForm(el, formId, addSetting);
      });
    }
  }
}
async function _launchLK(selector) {
  let formId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  let addSetting = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  let node = document.querySelectorAll(selector);
  if (node.length > 0) {
    const organizer = new QFormOrganizer(false);
    await organizer.init();
    //добавляем лоадер к форме
    organizer.addLoaderElement(node);
    if (!formId) {
      formId = node.dataset.formid || null;
    }
    if (formId) {
      node.forEach(el => {
        organizer.generateOneForm(el, formId, addSetting, true);
      });
    } else {
      console.warn('Qform->launchForm: "Не найден идентификатор формы!"');
    }
  } else {
    console.warn('Qform->launchForm: "Не найден родительский элемент формы!" Селектор: ' + selector);
  }
}

// ========== START
(function () {
  // function init() {
  launchAuto();
  // _launchLK('div[data-formid]', 'form_OIsztfVL2BN1QLjO-7KwhD_KS2cMovj5');
  // }

  // document.addEventListener("readystatechange", () => {
  // init();
  // });r
  // if (document.readyState == "complete") {
  //   init();
  // } else {
  //   if (window.attachEvent) {
  //     window.attachEvent("onload", init);
  //   } else {
  //     window.addEventListener("load", init, false);
  //   }
  // }
})();

export { launchAuto, launchForm, _launchLK };